import React from "react";

import {
	AppBar,
	Box,
	Button,
	IconButton,
	Toolbar,
	Typography,
} from "@mui/material";

import { Trans } from "@lingui/macro";

import "./style.css";
import {
	User,
	UserRole
} from "../../pages/models";
import {
	Link
} from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { SessionService } from "../../services/session.service";
import { KeycloakInstance } from "keycloak-js";

type S = {
	user: User | null
}

interface HeaderProps {
	keycloak: KeycloakInstance | null;
	unauthenticatedRoute: boolean;
}

export class Header extends React.Component<HeaderProps, S> {
	constructor(props: any) {
		super(props);

		this.state = {
			user: null
		};
	}

	getUserSuccessCallback = () => {

		let currentUserString = window.sessionStorage.getItem("currentUser");
		let currentUser: any = null;

		if (currentUserString != null) {
			currentUser = JSON.parse(currentUserString);
		}

		this.setState(() => ({
			user: currentUser
		}));
	};

	getUserErrorCallback = () => {
		this.props.keycloak?.clearToken();
		this.props.keycloak!.logout({
			redirectUri: process.env.REACT_APP_BASE_URL + "/"
		});
	};

	componentDidUpdate(prevProps: Readonly<HeaderProps>, prevState: Readonly<S>, snapshot?: any) {
		if (this.state.user === null && !this.props.unauthenticatedRoute) {
			SessionService.getCurrentUser(this.getUserSuccessCallback, this.getUserErrorCallback);
		}
	}

	changePassword = () => {
		this.props.keycloak?.clearToken();
		this.props.keycloak!.login({
			redirectUri: process.env.REACT_APP_BASE_URL + "/customers/change-password"
		});
	};

	logout = () => {

		this.props.keycloak?.clearToken();
		this.props.keycloak!.logout({
			redirectUri: process.env.REACT_APP_BASE_URL + "/"
		});
	};

	render() {
		return (<Box component="header">
			<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					{this.state.user && <Link to={"/customers"}
						style={{
							textDecoration: "none",
							color: "black"
						}}>
						<Typography variant="h6" sx={{color:"white"}} component="div">
							{process.env.REACT_APP_PRODUCT_NAME}
						</Typography>
					</Link>}
					{!this.state.user && <Typography variant="h6" component="div">
						{process.env.REACT_APP_PRODUCT_NAME}
					</Typography>}
					{(this.state.user && this.state.user.role === UserRole.OFFICE_ADMIN) &&
						<Button variant="outlined"

							href={"/manage-users"}
							startIcon={<PeopleIcon />}
							sx={{
								ml: 3,
								color: "white"
							}}>
							<Trans id={"h-manage-users"}>
								Gestione Utenti
							</Trans>
						</Button>}
					<Typography sx={{ flexGrow: 1 }} />
					{this.state.user && (<Typography variant="body1" component="div">
						<Trans id="header.welcome" comment="Welcome text for the page header">
							Ciao,
						</Trans>
						{this.state.user.name}
					</Typography>)}
					{this.state.user && <IconButton aria-label="changePassword"
						component="span"
						onClick={this.changePassword}
						sx={{ ml: 2 }}>
						<LockRoundedIcon />
					</IconButton>}
					{this.state.user &&
						<IconButton aria-label="logout" component="span" onClick={this.logout} sx={{ ml: 2 }}>
							<LogoutIcon />
						</IconButton>}
				</Toolbar>
			</AppBar>
			{/*<Sidebar />*/}
		</Box>);
	}
}
