import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";

import {
    CustomerCardProps,
    CustomerCardState
} from "./models";
import {
    CustomerStatus,
    UserRole
} from "../../../pages/models";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Stack,
    Typography
} from "@mui/material";

import { ConfirmDialog } from "@sinossi/mates-react-library";

import CheckIcon from "@mui/icons-material/Check";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import PanToolIcon from "@mui/icons-material/PanTool";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import { DateTime } from "luxon";

import {
    t,
    Trans
} from "@lingui/macro";
import { CustomersService } from "../../../services/customers.service";
import {
    withSnackbar,
    WithSnackbarProps
} from "notistack";

class CustomerCard extends React.Component<CustomerCardProps & WithSnackbarProps, CustomerCardState> {
    constructor(props: any) {
        super(props);

        this.state = {
            confirmDeleteDialogShow: false,
            repeatVerifieCustomer: false
        };
    }

    private confirmDeleteOpen = () => {
        this.setState(() => ({ confirmDeleteDialogShow: true }));
    };

    private confirmDeleteCallback = () => {
        this.setState(() => ({ confirmDeleteDialogShow: false }));
        this.props.emitDeleteCallback(this.props.customer.id);
    };

    private confirmRepeatVerifieOpen = () => {
        this.setState(() => ({ repeatVerifieCustomer: true }));
    };

    private customerRepeatVerifieCallback = (response: any) => {
        if (response.status !== 200) {
            this.props.enqueueSnackbar(t({
                id: "ccp-error-repeat-verifie",
                message: "Si è verificato un errore durante la verifica"
            }), {
                variant: "error",
            });

        } else {

            this.props.enqueueSnackbar(t({
                id: "ccp-success-repeat-verifie",
                message: "La verifica è avvenuta con successo"
            }), {
                variant: "success",
            });
        }
    }

    private confirmRepeatVerifieCallback = () => {
        this.setState(() => ({ repeatVerifieCustomer: false }));
        CustomersService.repeatVerifieCustomer(this.props.customer.id, this.customerRepeatVerifieCallback)
    }

    private formatItalianDate = (value: string | null | undefined) => {

        if (!value)
            return "/";

        if (value !== null) {
            return DateTime.fromMillis(Date.parse(value)).toFormat("dd/MM/yyyy");
        }

        return "/";
    };

    private formatItalianDateTime = (value: string | null | undefined) => {

        if (!value)
            return "/";

        if (value !== null) {
            return DateTime.fromMillis(Date.parse(value)).toFormat("dd/MM/yyyy HH:mm");
        }

        return "/";
    };
    render() {
        return (
            <Card style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <ConfirmDialog ok={this.confirmDeleteCallback}
                    open={this.state.confirmDeleteDialogShow}
                    message={t({
                        id: "cc-hide-confirm-msg",
                        message: "Nascondere il cliente. Procedere?"
                    })}
                    okText={t({
                        id: "cc-delete-confirm-btn",
                        message: "Conferma"
                    })}
                    cancelText={t({
                        id: "cc-delete-cancel-btn",
                        message: "Annulla"
                    })}
                    title={t({
                        id: "cc-hide-modal-title",
                        message: "Nascondi cliente"
                    })}
                    cancel={() => this.setState(() => ({ confirmDeleteDialogShow: false }))} />

                <ConfirmDialog
                    cancel={() => this.setState(() => ({ repeatVerifieCustomer: false }))}
                    ok={this.confirmRepeatVerifieCallback}
                    open={this.state.repeatVerifieCustomer}
                    message={t({
                        id: "cc-repeat-verifie-msg",
                        message: "Sei sicuro di voler ripetere la verifica?"
                    })}
                    title={t({
                        id: "cc-repeat-verifie-title",
                        message: "Ripeti verifica"
                    })} />

                <CardContent >
                    <Typography sx={{
                        fontSize: 10,
                        mb: 2
                    }} color="text.secondary">
                        {this.props.customer.id}
                    </Typography>
                    <Stack direction={"row"} spacing={1} style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        {this.props.customer.status === CustomerStatus.CREATED && <>
                            <HourglassTopIcon color={"error"} />
                            <Typography variant="body1" component="div" color={"red"}>
                                <Trans id={"cc-identification-in-progress"}>
                                    Identificazione in Corso
                                </Trans>
                            </Typography>
                        </>}
                        {this.props.customer.status === CustomerStatus.NOT_VERIFIED && <>
                            <HighlightOffIcon color={"error"} />
                            <Typography variant="body1" component="div" color={"red"}>
                                <Trans id={"cc-not-verified"}>
                                    Verifica Fallita
                                </Trans>
                            </Typography>
                        </>}
                        {this.props.customer.status === CustomerStatus.EXPIRED_ADV && <>
                            <EventBusyIcon color={"error"} />
                            <Typography variant="body1" component="div" color={"red"}>
                                <Trans id={"cc-expired-adv"}>
                                    Verifica scaduta
                                </Trans>
                            </Typography>
                        </>}
                        {this.props.customer.status === CustomerStatus.EXPIRED_DOCUMENTS && <>
                            <EventBusyIcon color={"error"} />
                            <Typography variant="body1" component="div" color={"red"}>
                                <Trans id={"cc-expired-docs"}>
                                    Documenti Scaduti
                                </Trans>
                            </Typography>
                        </>}
                        {this.props.customer.status === CustomerStatus.VERIFIED && <>
                            <CheckIcon color={"success"} />
                            <Typography variant="body1" component="div" color={"green"}>
                                <Trans id={"cc-verified"}>
                                    Cliente verificato
                                </Trans>
                            </Typography>
                        </>}
                        {this.props.customer.status === CustomerStatus.INSERTED_DATA && <>
                            <PanToolIcon color={"warning"} />
                            <Typography variant="body1" component="div" color={"orange"}>
                                <Trans id={"cc-to-verify"}>
                                    Verifica richiesta
                                </Trans>
                            </Typography>
                        </>}
                        {this.props.customer.status === CustomerStatus.WAITING_DOCUMENTS && <>
                            <FingerprintIcon color={"warning"} />
                            <Typography variant="body1" component="div" color={"orange"}>
                                <Trans id={"cc-waiting-documents"}>
                                    In attesa dei documenti
                                </Trans>
                            </Typography>
                        </>}
                    </Stack>
                    <Typography variant="h5" component="div" sx={{ mt: 1 }}>
                        {this.props.customer.denomination !== null ? this.props.customer.denomination : this.props.customer.name + " " + this.props.customer.surname}
                    </Typography>
                    <hr />
                    {this.props.customer.status === CustomerStatus.CREATED && <>
                        <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                            <b>  <Trans id={"cc-adv-start-date"}> ADV iniziata il </Trans> </b> {this.props.customer.lastAdvStartDate !== null ? this.formatItalianDateTime(this.props.customer.lastAdvStartDate) : "/"}
                        </Typography>
                    </>}
                    {this.props.customer.status === CustomerStatus.INSERTED_DATA && <>
                        <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                            <b>  <Trans id={"cc-adv-end-date"}> ADV terminata il </Trans> </b> {this.props.customer.lastAdvEndDate !== null ? this.formatItalianDateTime(this.props.customer.lastAdvEndDate) : "/"}
                        </Typography>
                    </>}
                    <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                        <b> CF </b> {this.props.customer.fiscalCode !== null ? this.props.customer.fiscalCode : "/"}
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                        <b>
                            <Trans id={"cc-adv-expiry"}> Scadenza ADV </Trans>
                        </b> {this.formatItalianDate(this.props.customer.verificationExpireDate)}
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                        <b>
                            <Trans id={"cc-worker"}> Professionista </Trans>
                        </b> {this.props.customer.worker.name !== null ? this.props.customer.worker.name + " " + this.props.customer.worker.surname : "/"}
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                        <b>
                            <Trans id={"cc-management-code"}> Codice gestionale </Trans>
                        </b> {(this.props.customer.managementCode !== null && this.props.customer.managementCode !== "") ? this.props.customer.managementCode : "/"}
                    </Typography>
                </CardContent>
                {!this.props.customer.deleted && <CardActions style={{ marginTop: "auto" }}>
                    <Button size="small"
                        color={"error"}
                        sx={{ mr: 1 }}
                        onClick={this.confirmDeleteOpen}> <Trans id={"cc-hide"}> Nascondi </Trans>
                    </Button>
                    {((this.props.customer.status === CustomerStatus.VERIFIED || this.props.customer.status === CustomerStatus.WAITING_DOCUMENTS) || this.props.customer.status === CustomerStatus.CREATED || this.props.customer.status === CustomerStatus.NOT_VERIFIED) &&
                        <Link to={`/customer-summary/${this.props.customer?.id}?backTo=/customers`}>
                            <Button size="small"> <Trans id={"cc-details"}> Dettagli </Trans></Button>
                        </Link>}
                    {this.props.customer.status === CustomerStatus.INSERTED_DATA && this.props.user?.role !== UserRole.SECRETARY &&
                        <Link to={`/verify/${this.props.customer.id}`}>
                            <Button size="small"> <Trans id={"cc-verify"}> Verifica </Trans> </Button> </Link>}
                    {this.props.customer.status === CustomerStatus.EXPIRED_ADV &&
                        <Link to={`/verify/${this.props.customer.id}`}>
                            <Button size="small"
                                sx={{ mr: 1 }}>
                                <Trans id={"cc-repeat-verify"}> Ripeti
                                    verifica </Trans>
                            </Button>
                        </Link>}
                    {this.props.customer.status === CustomerStatus.VERIFIED &&
                        <Link to={`/analyses/${this.props.customer.id}`}>
                            <Button size="small"> <Trans id={"cc-operations"}> Operazioni </Trans> </Button></Link>}
                </CardActions>}
            </Card>);
    }
}

export default withSnackbar(withRouter(CustomerCard));