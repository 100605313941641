import React from "react";
import { Redirect } from "react-router-dom";

import { CustomerRecapInformationsProps, CustomerRecapInformationsState } from "./models";

import { withSnackbar, WithSnackbarProps } from "notistack";

import { Customer, CustomerStatus, UserRole } from "../../../pages/models";
import CustomerOtherDataForm from "../customer-other-data-form/customer-other-data-form";

import CustomerForm from "../customer-form/customer-form";

import { t, Trans } from "@lingui/macro";
import { Button, Dialog, Stack, Typography } from "@mui/material";

import { CustomersService } from "../../../services/customers.service";
import CustomerRelatedPerson from "./customer-related-person/customer-related-person";

import { ErrorResponse, Loading, RestResponse } from "@sinossi/mates-react-library";
import { SessionService } from "../../../services/session.service";
import SelectedWorkerModal from "../selected-worker-modal/selected-worker-modal";
import UploadDocumentWorkerModal from "../upload-document-worker-modal/upload-document-worker-modal";

import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

class CustomerRecapInformations extends React.Component<CustomerRecapInformationsProps & WithSnackbarProps, CustomerRecapInformationsState> {
    constructor(props: any) {
        super(props);

        this.state = {
            success: false,
            isUploadDocumentModalOpen: false,
            isSelectedWorkerModalOpen: false,
            selectedWorker: null,
            currentUser: null,
            loading: false
        };
    }

    getUserErrorCallback = () => {
        this.props.enqueueSnackbar(t({
            id: "cri-error-get-user",
            message: "Si è verificato un errore durante l'ottenimento dell'utenza di lavoro"
        }), {
            variant: "error",
        });
    };

    getUserSuccessCallback = () => {

        let currentUserString = window.sessionStorage.getItem("currentUser");
        let currentUser: any = null;

        if (currentUserString != null) {
            currentUser = JSON.parse(currentUserString);
        }

        this.setState(() => ({
            currentUser: currentUser
        }));
    };


    componentDidMount() {

        this.setState(() => ({ selectedWorker: this.props.customer?.worker }));

        SessionService.getCurrentUser(this.getUserSuccessCallback, this.getUserErrorCallback)
    }

    private verifyCustomer = () => {
        CustomersService.verifyCustomer(this.props.customer!.id, true, this.verifyCustomerCallback);
    };

    private dontVerifyCustomer = () => {
        CustomersService.verifyCustomer(this.props.customer!.id, false, this.verifyCustomerCallback);
    };

    private verifyCustomerCallback = (response: RestResponse<Customer, ErrorResponse>) => {

        if (!response.hasError()) {
            this.setState(() => ({ success: true }));
        } else {
            this.props.enqueueSnackbar(t({
                id: "cri-error-verifiy",
                message: "Si è verificato un errore durante l'aggiornamento della verifica del cliente"
            }), {
                variant: "error",
            });
        }
    };

    private uploadSuccess = (response: any) => {

        this.setState(() => ({ isUploadDocumentModalOpen: false, loading: false }));

        this.props.getCustomer();
    };

    private uploadError = (response: any) => {

        this.setState(() => ({ loading: false }));

        this.props.enqueueSnackbar(t({
            id: "cri-error-upload",
            message: "Si è verificato un errore durante il caricamento del documento"
        }), {
            variant: "error",
        });
    };

    uploadDocumentClose = (document: any) => {

        this.setState(() => ({ isUploadDocumentModalOpen: false, loading: true }));

        if (document != null) {
            document.documentType = document.documentType.type;
            document.referencedEntityId = this.props.customer!.id;

            CustomersService.uploadDocument(document.file, document, this.uploadSuccess, this.uploadError);
        }
        this.setState(() => ({ loading: false }));
    };

    openUploadDocumentModal = () => {
        this.setState(() => ({ isUploadDocumentModalOpen: true }));
    };

    updateWorkerCallback = (response: any) => {

        if (!response.hasError()) {
            this.setState(() => ({ selectedWorker: response.data.worker }));
        } else {
            this.props.enqueueSnackbar(t({
                id: "cri-error-update-worker",
                message: "Si è verificato un errore durante la modifica del professionista"
            }), {
                variant: "error",
            });
        }
    }

    checkUserCanVerify = (): boolean => {

        if (!this.state.currentUser)
            return false;

        if (this.state.currentUser.role === UserRole.SECRETARY)
            return false;

        if (this.state.currentUser.id !== this.props.customer?.worker.id)
            return false;

        return (this.props.customer?.status === CustomerStatus.INSERTED_DATA || this.props.customer?.status === CustomerStatus.EXPIRED_ADV);
    }


    checkUserCanChangeWorker = (): boolean => {


        if (!this.state.currentUser)
            return false;

        if (this.state.currentUser.role === UserRole.WORKER)
            return false;

        return true;
    }

    selectedWorkerClose = (workerId: any) => {

        this.setState(() => ({
            isSelectedWorkerModalOpen: false
        }));

        if (workerId && workerId !== this.state.selectedWorker?.id) {
            CustomersService.updateWorker(this.props.customer?.id, workerId, this.updateWorkerCallback);
        }
    };

    openSelectedWorkerModal = () => {
        this.setState(() => ({ isSelectedWorkerModalOpen: true }));
    };

    resendOnboardingMail = () => {
        CustomersService.resendOnboardingMail(this.props.customer?.id, this.resendMailCallback);
    }

    manualConfirmDocuments = () => {
        CustomersService.manualConfirmDocuments(this.props.customer?.id, this.manualConfirmDocumentsCallback);
    }

    private resendMailCallback = (response: any) => {

        if (response.status() !== 200) {
            this.props.enqueueSnackbar(t({
                id: "cri-error-resend-onboarding-mail",
                message: "Si è verificato un errore durante l'invio dell'email"
            }), {
                variant: "error",
            });
        } else {
            this.props.enqueueSnackbar(t({
                id: "cri-success-resend-onboarding-mail",
                message: "L'email è stata inviata"
            }), {
                variant: "success",
            });
        }
    };

    private manualConfirmDocumentsCallback = (response: any) => {

        if (response.status() !== 200) {
            this.props.enqueueSnackbar(t({
                id: "cri-error-approve-documents",
                message: "Si è verificato un errore durante la conferma dell'inserimento dati"
            }), {
                variant: "error",
            });
        } else {
            this.props.enqueueSnackbar(t({
                id: "cri-success-confirm-data",
                message: "Dati confermati correttamente"
            }), {
                variant: "success",
            });

            window.location.reload();
        }
    };

    render() {
        return (<>
            <Loading show={this.state.loading} />
            <Dialog
                maxWidth={"md"}
                open={this.state.isUploadDocumentModalOpen}>
                <UploadDocumentWorkerModal closeModal={this.uploadDocumentClose} />
            </Dialog>
            <Dialog
                maxWidth={"sm"}
                fullWidth
                open={this.state.isSelectedWorkerModalOpen}>
                <SelectedWorkerModal worker={this.state.selectedWorker} closeModal={this.selectedWorkerClose} />
            </Dialog>
            {this.state.success && <Redirect to="/verify-success" />}
            <Typography variant="body1"
                sx={{
                    fontWeight: "bold",
                    mb: 3
                }}>
                <Trans id="cri-data">
                    Dati generali del cliente
                </Trans>
            </Typography>
            <CustomerForm customer={this.props.customer} readOnly={true} />
            {this.props.customer?.ownerList?.length !== 0 && <>
                <Typography variant="body1"
                    sx={{
                        fontWeight: "bold",
                        mt: 2,
                        mb: 3
                    }}>
                    <Trans id="cri-owners-data">
                        Dati delle persone di interesse
                    </Trans>
                </Typography>
                {this.props.customer?.ownerList!.map(
                    (person) => (<CustomerRelatedPerson person={person} key={person.id} />))}
            </>}
            <Typography variant="body1"
                sx={{
                    fontWeight: "bold",
                    mt: 2,
                    mb: 3
                }}>
                <Trans id="cri-customer-other-data">
                    Ulteriori dati del cliente
                </Trans>
            </Typography>
            <CustomerOtherDataForm customer={this.props.customer} />
            <Button
                onClick={this.openUploadDocumentModal}
                color="primary"
                size={"small"}
                variant="outlined">
                <Trans id={"cri-upload"}>Carica documento aggiuntivo</Trans>
            </Button>
            <>
                <Typography variant="body1"
                    sx={{
                        fontWeight: "bold",
                        mt: 2,
                        mb: 2
                    }}>
                    <Trans id="cri-customer-worker">
                        Professionista
                    </Trans>
                </Typography>
                <Typography variant="body1"
                    sx={{
                        mb: 2,
                    }}>
                    <i>{this.state.selectedWorker?.name} {this.state.selectedWorker?.surname}</i>
                </Typography>
                <Button
                    onClick={this.openSelectedWorkerModal}
                    color="primary"
                    size={"small"}
                    variant="outlined"
                    disabled={!this.checkUserCanChangeWorker()}
                >

                    <Trans id={"cri-selected-worker"}>Cambia professionista di riferimento</Trans>
                </Button>
            </>
            <Stack direction={"row"}
                justifyContent={"flex-end"}
                sx={{
                    mt: 2,
                    width: "100%"
                }}
                spacing={2}>
                {this.props.customer?.status !== CustomerStatus.VERIFIED && <Button
                    onClick={this.resendOnboardingMail}
                    color="secondary"
                    startIcon={<ScheduleSendIcon />}
                    variant="contained">
                    <Trans id={"cri-resend-mail"}>Rinvio email di identificazione</Trans>
                </Button>}
                {this.props.customer?.status === CustomerStatus.WAITING_DOCUMENTS && <Button
                    onClick={this.manualConfirmDocuments}
                    color="primary"
                    startIcon={<ThumbUpOffAltIcon />}
                    variant="contained">
                    <Trans id={"cri-confirm-docs"}> Conferma documenti</Trans>
                </Button>}
                {this.checkUserCanVerify() && <>
                    <Button
                        onClick={this.dontVerifyCustomer}
                        color="secondary"
                        variant="outlined">
                        <Trans id={"cri-not-verify"}>Non verificato</Trans>
                    </Button>
                    <Button
                        onClick={this.verifyCustomer}
                        color="primary"
                        variant="contained">
                        <Trans id={"cri-verify"}>Verifica</Trans>
                    </Button>
                </>}
            </Stack>
        </>);
    }
}

export default withSnackbar(CustomerRecapInformations);
