import React from "react";

import Box from "@mui/material/Box";
import {
	Container,
	CssBaseline,
} from "@mui/material";

import {
	RouteComponentProps,
	withRouter,
} from "react-router-dom";
import { t } from "@lingui/macro";
import Keycloak from "keycloak-js";

import {
	ErrorDialog,
	Loading,
	NetworkService,
	NumberUtils,
} from "@sinossi/mates-react-library";

import {
	Header,
	Footer
} from "structure";
import Routes from "./routes";

type AppProps = {} & RouteComponentProps

interface AppState {
	authenticated: boolean,
	keycloak: Keycloak.KeycloakInstance | null,
	authenticationError: boolean,
	errorDialog: {
		open: boolean, title: string, message: string,
	},
}

class App extends React.Component<AppProps, AppState> {
	constructor(props: any) {
		super(props);
		this.state = {
			authenticated: false,
			keycloak: null,
			authenticationError: false,
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		};
	}

	componentDidMount() {

		const config = {
			url: process.env.REACT_APP_KEYCLOAK_URL as string,
			realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
			clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
		};

		const keycloak = new Keycloak(config);

		keycloak
			.init({ onLoad: "check-sso" })
			.then((authenticated: boolean) => this.onLoginRequired(keycloak, authenticated))
			.catch(reason => {
				console.error("Cannot init keycloak");
				console.error(reason);

				this.setState(() => ({
					keycloak: keycloak,
					authenticated: false,
					authenticationError: true,
				}));

				NetworkService.removeAuthenticationToken();

				this.props.history.push("/keycloak-error");
			});

		keycloak.onTokenExpired = () => this.onTokenExpired(keycloak);
	}

	private onLoginRequired = (keycloak: Keycloak.KeycloakInstance, authenticated: boolean) => {

		NetworkService.saveAuthenticationToken(keycloak.token || "");
		this.setState(() => ({
			keycloak: keycloak,
			authenticated: authenticated,
		}));

	};

	private onTokenExpired = (keycloak: Keycloak.KeycloakInstance) => {

		const keycloakTokenValidity: number = NumberUtils.toInteger(
			process.env.REACT_APP_KEYCLOAK_TOKEN_VALIDITY as string, 30);

		keycloak
			.updateToken(keycloakTokenValidity)
			.then(() => {
				//console.log("Successfully get a new token", keycloak.token);
				NetworkService.saveAuthenticationToken(keycloak.token || "");
			})
			.catch(() => {
				console.log("error refreshing token.");
				this.setState(() => ({
					errorDialog: {
						open: true,
						title: t({
							id: "auth.error.title",
							message: "Errore di autenticazione",
						}),
						message: t({
							id: "auth.error.cannot_refresh",
							message: "La sessione è scaduta, effettuare nuovamente l’autenticazione",
						}),
					},
				}));
			});
	};

	private onErrorDialogClose = (): void => {
		this.setState(() => ({
			errorDialog: {
				open: false,
				title: "",
				message: "",
			}
		}));
		this.state.keycloak?.logout();
	};

	checkIsAPageWithHeader = () => {
		const ROUTES_WITHOUT_HEADER = [
			"/"
		];

		let returnValue = !ROUTES_WITHOUT_HEADER.includes(this.props.location.pathname);

		returnValue = returnValue && this.checkDontStartsWith(ROUTES_WITHOUT_HEADER, this.props.location.pathname);

		return returnValue;
	};

	checkIsUnauthenticatedRoute = () => {
		const UNAUTHROUTES = [
			"/",
			"/end",
			"/privacy-agreement",
			"/forbidden",
			"/identification-success"
		];

		let returnValue = !UNAUTHROUTES.includes(this.props.location.pathname);

		returnValue = returnValue && this.checkDontStartsWith(UNAUTHROUTES, this.props.location.pathname);

		return returnValue;
	};

	private checkDontStartsWith = (routeUrl: Array<string>, pathname: string) => {
		let result = true;

		for (let el of routeUrl) {
			if (pathname.startsWith(el) && el !== "/") {
				result = false;
			}
		}

		return result;
	};

	private getBackgroundClassBasedOnEnv(): string {

		if ((process.env.REACT_APP_PRODUCT_NAME || "").includes("LawaL")) {
			return "lawalBackgroundClass";
		}


		if ((process.env.REACT_APP_PRODUCT_NAME || "").includes("MGI")) {
			return "mgiBackgroundClass";
		}

		return "standardBackgroundClass";
	}

	public render() {
		let displayHeader = this.checkIsAPageWithHeader();

		let unauthenticatedRoute = this.checkIsUnauthenticatedRoute();

		return (<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
			}}
			className={`${(this.props.location.pathname === "/") ? this.getBackgroundClassBasedOnEnv() : ""}`}>
			<CssBaseline />
			<Box sx={{ display: "flex" }}>
				{displayHeader &&
					<Header keycloak={this.state.keycloak} unauthenticatedRoute={!unauthenticatedRoute} />}
				<Container component="main"
					sx={{
						flexGrow: 1,
						p: 3,
					}}>
					<Box
						sx={{
							bgcolor: "background.paper",
							mb: 8,
							mt: 8,
						}}>
						{this.state.keycloak && < Routes keycloak={this.state.keycloak} />}
						{!this.state.keycloak && <Loading show={true} />}
					</Box>
				</Container>
			</Box>
			<Footer />
			<ErrorDialog
				ok={this.onErrorDialogClose}
				{...this.state.errorDialog} />
		</Box>);
	}

}

export default withRouter(App);
