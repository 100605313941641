export class MainConstants {
	static USER_ROLES = [
		{
			key: "WORKER",
			label: "Professionista",
			labelEn: "Worker"
		},
		{
			key: "SECRETARY",
			label: "Segretario/a",
			labelEn: "Secretary"
		},
		{
			key: "OFFICE_ADMIN",
			label: "Office Manager",
			labelEn: "Office Admin"
		}
	];

	static DOCUMENT_TYPE_LIST = [
		{
			key: "BANK_ACCOUNT_STATEMENT",
			label: "Estratto Conto",
			labelEn: "Bank Account Statement"
		},
		{
			key: "LAST_BALANCE",
			label: "Ultimo Bilancio",
			labelEn: "Last Balance"
		},
		{
			key: "CCIA_REPORT",
			label: "Visura Camerale",
			labelEn: "Company Registration Report"
		},
		{
			key: "OWNER_APPOINTMENT_VERBAL",
			label: "Verbale di definizione degli amministratori",
			labelEn: "Owner appointment verbal"
		},
		{
			key: "COMPANY_CONSTITUTION_ACT",
			label: "Atto costitutivo",
			labelEn: "Company Constitution Act"
		}
	];
	static CUSTOMER_LEGAL_NATURE_LIST = [
		{
			"key": "AP",
			"label": "Associazione professionale",
			"labelEn": "Professional Association"
		},
		{
			"key": "DI",
			"label": "Impresa Individuale",
			"labelEn": "Individual Enterprise"
		},
		{
			"key": "PF",
			"label": "Persona Fisica",
			"labelEn": "Natural Person"
		},
		{
			"key": "PP",
			"label": "Professionista",
			"labelEn": "Professional"
		},
		{
			"key": "SC",
			"label": "Società di capitali",
			"labelEn": "Corporation"
		},
		{
			"key": "SP",
			"label": "Società di persone",
			"labelEn": "Partnership"
		},
		{
			"key": "SF",
			"label": "Società fiduciaria",
			"labelEn": "Trust company"
		},
		{
			"key": "TT",
			"label": "Trust",
			"labelEn": "Trust"
		},
	];
	static CUSTOMER_MAIN_ACTIVITY = [
		{
			key: "NORMAL",
			label: "Normale",
			labelEn: "Normal"
		},
		{
			key: "RELEVANT",
			label: "Rilevante",
			labelEn: "Relevant"
		},
		{
			key: "ABNORMAL",
			label: "Anormale",
			labelEn: "Abnormal"
		}
	];
	static GEO_AREA = [
		{
			key: "ITALY",
			label: "Italia",
			labelEn: "Italy"
		},
		{
			key: "EU",
			label: "Paesi dell'Unione Europea",
			labelEn: "EU Countries"
		},
		{
			key: "EXTRA_EU",
			label: "Paesi fuori dall'Unione Europea",
			labelEn: "Extra-EU Countries"
		},
		{
			key: "OFF_SHORE",
			label: "Territori Off-Shore",
			labelEn: "Off-Shore Countries"
		}
	];
	static MONEY_ORIGIN = [
		{
			key: "SELF_MONEY_OR_ACTIVITY",
			label: "Fondi propri e/o redditi di attività propria",
			labelEn: "Own funds or incomes from own business"
		},
		{
			key: "SELF_AND_THIRD_PARTY_MONEY",
			label: "Fondi propri e di terzi già nella disponibilità",
			labelEn: "Own and third party funds already available"
		},
		{
			key: "PARTNER_LOAN",
			label: "Finanziamento dei soci",
			labelEn: "Partner Loan"
		},
		{
			key: "PRIVATE_LOAN",
			label: "Finanziamento specifico da privati",
			labelEn: "Private Loan"
		},
		{
			key: "BANK_LOAN",
			label: "Finanziamento specifico da banca (indicare la banca)", //TODO: handle bank
			labelEn: "Bank Loan"
		},
		{
			key: "COMPANY_LOAN",
			label: "Finanziamento da società controllante o altra società del gruppo",
			labelEn: "Company Loan"
		},
		{
			key: "DONATION",
			label: "Donazione",
			labelEn: "Donation"
		},
		{
			key: "INHERITANCE",
			label: "Eredità",
			labelEn: "Inheritance"
		},
		{
			key: "OTHER", //TODO: handle other
			label: "Altro",
			labelEn: "Other"
		}
	];

	static OPERATION_FREQUENCY_LIST = [
		{
			key: "SPOT",
			label: "Spot",
			labelEn: "Spot"
		},
		{
			key: "INFREQUENT",
			label: "Non frequente",
			labelEn: "Infrequent"
		},
		{
			key: "FREQUENT",
			label: "Frequente",
			labelEn: "Frequent"
		}
	];
	static OPERATION_EXECUTION_MODE_LIST = [
		{
			key: "WITH_BANKS",
			label: "Con Banche",
			labelEn: "With Banks"
		},
		{
			key: "PRIVATE_SUBJECTS",
			label: "Tra soggetti privati",
			labelEn: "Between Private Parties"
		}
	];
	static OPERATION_VALUE_LIST = [
		{
			key: "LOW",
			label: "Basso",
			labelEn: "Low"
		},
		{
			key: "MEDIUM",
			label: "Medio",
			labelEn: "Medium"
		},
		{
			key: "HIGH",
			label: "Alto",
			labelEn: "High"
		}
	];
	static ADV_TYPE_LIST = [
		{
			key: "SIMPLIFIED",
			label: "Semplificata",
			labelEn: "Simplified"
		},
		{
			key: "NORMAL",
			label: "Ordinaria",
			labelEn: "Ordinary"
		},
		{
			key: "REINFORCED",
			label: "Rafforzata",
			labelEn: "Reinforced"
		}
	];
	static ADV_EXPIRY_LIST = [
		{
			key: "SIX_MONTHS",
			label: "6 Mesi",
			labelEn: "6 Months"
		},
		{
			key: "ONE_YEAR",
			label: "12 Mesi",
			labelEn: "12 Months"
		},
		{
			key: "TWO_YEARS",
			label: "2 Anni",
			labelEn: "2 Years"
		},
		{
			key: "THREE_YEARS",
			label: "3 Anni",
			labelEn: "3 Years"
		}
	];

	static EXEMPTION_REASON_LIST = [
		{
			key: "WITH_PA",
			label: "L'operazione si svolge per una Pubblica Amministrazione"
		},
		{
			key: "GENERAL_LOW_RISK",
			label: "L'operazione presenta generalmente un rischio basso"
		}
	];

	static USER_ROLE_LIST = [
		{
			key: "OFFICE_ADMIN",
			label: "Admin",
			labelEn: "Admin"
		},
		{
			key: "WORKER",
			label: "Professionista",
			labelEn: "Worker"
		},
		{
			key: "SECRETARY",
			label: "Segretario/a",
			labelEn: "Secretary"
		}


	]

	static CUSTOMER_STATUS_LIST = [
		{
			key: "CREATED",
			label: "Identificazione in corso",
			labelEn: "Identification in progress"
		},
		{
			key: "INSERTED_DATA",
			label: "Da verificare",
			labelEn: "To be checked"
		},
		{
			key: "NOT_VERIFIED",
			label: "Non verificati",
			labelEn: "Not verified"
		},
		{
			key: "VERIFIED",
			label: "Verificati",
			labelEn: "Verified"
		},
		{
			key: "EXPIRED_ADV",
			label: "Verifica scaduta",
			labelEn: "Verification expired"
		}
	];

	static MANDATE_TYPE = [
		{
			key: "VERBAL",
			label: "Mandato raccolto oralmente"
		},
		{
			key: "DOCUMENT",
			label: "Mandato documentale"
		},
	];

	static BUSINESS_TYPE = [
		{
			key: "A",
			label: "Agricoltura, Silvicoltura e Pesca"
		},
		{
			key: "B",
			label: "Estrazione di minerali da cave e miniere"
		},
		{
			key: "C",
			label: "Attività Manifatturiere"
		},
		{
			key: "D",
			label: "Fornitura di energia elettrica, gas, vapore e aria condizionata"
		},
		{
			key: "E",
			label: "Fornitura di acqua, reti fognarie, attività di gestione dei rifiuti e risanamento"
		},
		{
			key: "F",
			label: "Costruzioni"
		},
		{
			key: "G",
			label: "Commercio all'ingrosso e al Dettaglio, riparazione di autoveicoli e motocicli"
		},
		{
			key: "H",
			label: "Trasporto e magazzinaggio"
		},
		{
			key: "I",
			label: "Attività dei servizi di alloggio e di ristorazione"
		},
		{
			key: "J",
			label: "Servizi di informazione e comunicazione"
		},
		{
			key: "K",
			label: "Attività finanziarie e di comunicazione"
		},
		{
			key: "L",
			label: "Attività immobiliari"
		},
		{
			key: "M",
			label: "Attività professionali, scientifiche e tecniche"
		},
		{
			key: "N",
			label: "Noleggio, agenzie di viaggio, servizi di supporto alle imprese"
		},
		{
			key: "O",
			label: "Amministrazione pubblica e difesa, assicurazione sociale obbligatoria"
		},
		{
			key: "P",
			label: "Istruzione"
		},
		{
			key: "Q",
			label: "Sanità e assistenza sociale"
		},
		{
			key: "R",
			label: "Attività artistiche, sportive, di intrattenimento e divertimento"
		},
		{
			key: "S",
			label: "Altre attività di servizi"
		},
		{
			key: "T",
			label: "Attività di famiglie e convivenze come datori di lavoro per personale domestico; Produzione di beni e servizi indifferenziati per uso proprio da parte di famiglie e convivenze"
		},
		{
			key: "U",
			label: "Organizzazioni e organismi extraterritoriali"
		},
	];
}

export enum UserRole {
	OFFICE_ADMIN = "OFFICE_ADMIN", WORKER = "WORKER", SECRETARY = "SECRETARY"
}

export enum DocumentType {
	ID_CARD = "ID_CARD",
	OTHER_IDENTIFICATION_METHOD = "OTHER_IDENTIFICATION_METHOD",
	BANK_ACCOUNT_STATEMENT = "BANK_ACCOUNT_STATEMENT",
	DRIVING_LICENCE = "DRIVING_LICENCE",
	DURC = "DURC",
	LAST_BALANCE = "LAST_BALANCE",
	CCIA_REPORT = "CCIA_REPORT",
	OWNER_APPOINTMENT_VERBAL = "OWNER_APPOINTMENT_VERBAL",
	COMPANY_CONSTITUTION_ACT = "COMPANY_CONSTITUTION_ACT",
	OTHER = "OTHER",
}

export interface RequiredDocumentType {
	type: DocumentType,
	name: string | null
}

export enum MandateType {
	DOCUMENT = "DOCUMENT", VERBAL = "VERBAL"
}

export interface Mandate {
	id: string,
	type: MandateType,
	uri: string | null,
	fileName: string | null,
	uploadDate: Date | null
}

export interface Address {
	street: string,
	city: string,
	province: string,
	nation: string,
	zipCode: string
}

export enum OfficeType {

}

export interface Office {
	id: string,
	name: string,
	address: Address,
	email: string,
	vatCode: string,
	fiscalCode: string,
	boughtObjects: any,
	pec: string,
	type: OfficeType
	active: boolean
}

export interface User {
	id: string,
	name: string,
	surname: string,
	email: string,
	creationDate: any,
	lastLogin: any,
	role: UserRole,
	deleted: boolean,
	office: Office | null
}

export enum OperationFrequency {
	SPOT, INFREQUENT, FREQUENT
}

export enum ExecutionMode {
	WITH_BANKS, PRIVATE_SUBJECT
}

export enum OperationValue {
	LOW = ("LOW"), MEDIUM = ("MEDIUM"), HIGH = ("HIGH")
}

export enum GeoArea {
	ITALY, EU, EXTRA_EU, OFF_SHORE
}

export enum MoneyOrigin {
	SELF_MONEY_OR_ACTIVITY = ("SELF_MONEY_OR_ACTIVITY"),
	SELF_AND_THIRD_PARTY_MONEY = ("SELF_AND_THIRD_PARTY_MONEY"),
	PARTNER_LOAN = ("PARTNER_LOAN"),
	PRIVATE_LOAN = ("PRIVATE_LOAN"),
	BANK_LOAN = ("BANK_LOAN"),
	COMPANY_LOAN = ("COMPANY_LOAN"),
	DONATION = ("DONATION"),
	INHERITANCE = ("INHERITANCE"),
	OTHER = ("OTHER")
}

export interface Operation {
	id: string | null,
	shortDescription: string | null,
	description: string | null,
	frequency: OperationFrequency | null,
	executionMode: ExecutionMode | null,
	operationValue: OperationValue | null,
	geoArea: GeoArea | null,
	moneyOrigin: MoneyOrigin | null,
	moneyOriginNotes: string | null,
	extraordinary: boolean | null,
	congruous: boolean | null,
	managementCode: string | null,
	exent: boolean | null,
	exemptionReason: any | null,
	worker: Partial<User> | null
}

export enum LegalNature {
	AP = "AP",
	DI = "DI",
	PF = "PF",
	PP = "PP",
	SC = "SC",
	SP = "SP",
	SF = "SF",
	TT = "TT"
}

export enum VerificationType {
	SIMPLIFIED = "SIMPLIFIED", NORMAL = "NORMAL", REINFORCED = "REINFORCED"
}

export enum VerificationDuration {
	SIX_MONTHS = "SIX_MONTHS", ONE_YEAR = "ONE_YEAR", TWO_YEARS = "TWO_YEARS", THREE_YEARS = "THREE_YEARS"
}

export enum CustomerStatus {
	CREATED = "CREATED",
	DRAFT = "DRAFT",
	INSERTED_DATA = "INSERTED_DATA",
	VERIFIED = "VERIFIED",
	NOT_VERIFIED = "NOT_VERIFIED",
	EXPIRED_ADV = "EXPIRED_ADV",
	EXPIRED_DOCUMENTS = "EXPIRED_DOCUMENTS",
	WAITING_DOCUMENTS = "WAITING_DOCUMENTS"
}

export interface Customer {
	id: string | null;
	denomination: string | null;
	vatCode: string | null;
	fiscalCode: string | null;
	name: string | null;
	surname: string | null;
	legalNature: LegalNature | null;
	email: string | null;
	pec: string | null;
	geoArea: GeoArea | null;
	verificationType: VerificationType | null;
	verificationExpireDate: any | null;
	status: CustomerStatus | null;
	deleted: boolean | null;
	office: Office | null;
	additionalInfo: CustomerAdditionalInfo;
	efestoProcessId: string | null;
	ownerList: Array<Person> | null;
	contactName: string | null;
	contactSurname: string | null;
	contactPhone: string | null;
	managementCode: string | null;
	worker: any | null;
	documentList: Array<Document> | null;
	lastAdvStartDate?: string;
	lastAdvEndDate?: string;
}

export interface CustomerAdditionalInfo {
	collaborative: boolean | null,
	advDuration: any | null,
	ateco2007: string | null,
	mainActivity: any | null,
	constitutionDate: any | null,
	birthDate: any | null,
	address: string | null,
	businessType: any | null,
	authCode: any,
	requiredDocuments: Array<RequiredDocumentType> | null;
}

export interface PersonAdditionalInfo {
	pep: boolean | null;
	pepType: any | null;
	pepRole: any | null;
	requiredDocumentList: Array<RequiredDocumentType> | null;
}

export enum PersonType {
	OWNER = "OWNER", EXECUTOR = "EXECUTOR"
}

export interface Document {
	id: string;
	fileName: string;
	uri: string;
	type: DocumentType;
	size: number;
	hash: string;
	uploadDate: any;
	deleted: false;
	issuer: string;
	issueDate: any;
	expiryDate: any;
	referencedEntityId: string;
	uploadedByAnOfficeInternal: boolean;
}

export interface Person {
	id: string | null,
	name: string | null,
	surname: string | null,
	fiscalCode: string | null,
	birthDate: any | null,
	birthPlace: string | null,
	type: PersonType | null,
	residence: string | null,
	deleted: boolean | null,
	additionalInfo: PersonAdditionalInfo | null,
	customer: Customer | null,
	job: string | null,
	email: string | null,
	pec: string | null,
	signPowerRole: any | null,
	documentList: Array<Document> | null
}

export interface RiskValue {
	customerValue: number | null,
	operationValue: number | null,
	total: number | null,
	suggestedVerificationType: VerificationType,
	suggestedAdvDuration: VerificationDuration,
	editableSuggestedData: boolean | null
}

export interface Analysis {
	id: string | null,
	startDate: any | null,
	riskValue: RiskValue | null,
	operation: Operation | null,
	customer: Customer | null,
	textAnalysis: string | null,
	closer: any | null,
	closingDate: any | null,
	deleted: boolean | null,
	mandate: any | null,
	report: any | null
}

export enum PurcheaseableElementType {
	OFFICE_ACTIVATION = ("OFFICE_ACTIVATION"), RECHARGE = ("RECHARGE")
}

export interface PurchaseableElement {
	id: string,
	displayName: string,
	description: string,
	type: PurcheaseableElementType,
	amountExcludingVat: number,
	payload: any;
}

export enum OrderStatus {
	CREATED = ("CRATED"), SUCCESS = ("SUCCESS"), ERROR = ("ERROR")
}

export interface Order {
	id: string;
	date: any | null;
	amountExcludingVat: number;
	total: number;
	appliedVatPercentage: number;
	buyer: User;
	purchaseableElement: PurchaseableElement;
	status: OrderStatus;
}

export interface VatResponseName {
	value: string
}

export interface CheckVatResponse {
	countryCode: string,
	vatNumber: string,
	requestDate: Date,
	name: VatResponseName,
	address: string,
	valid: boolean
}

export interface CheckVatResponseObject {
	data: CheckVatResponse
}